import React, { useContext, useState } from "react";
import logo from "../assets/image/scheffer.png";
import { NavLink } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import HeaderTop from "./HeaderTop";
import { Helmet } from "react-helmet";
const Header = () => {
  const [open, setOpen] = useState(false);
  const { language, changeLanguage } = useContext(LanguageContext);
  const t = translations[language];
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    changeLanguage(newLanguage);
  };
  return (
    <>
    <Helmet>
      <title>{t.order}</title>
    </Helmet>
      <div style={{position:"sticky",top:0,zIndex:10,background:"#fff"}}>
        <HeaderTop/>
        <header className="header__section py-3">
          <div className="header__sticky">
            <div className="container-fluid">
              <div className="main__header d-flex justify-content-between align-items-center">
                <div className="offcanvas__header--menu__open ">
                  <a
                    className="offcanvas__header--menu__open--btn"
                    href="javascript:void(0)"
                    data-offcanvas
                    onClick={() => setOpen(!open)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ionicon offcanvas__header--menu__open--svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M80 160h352M80 256h352M80 352h352"
                      />
                    </svg>
                    <span className="visually-hidden">Offcanvas Menu Open</span>
                  </a>
                </div>
                <div className="main__logo">
                  <h1 className="main__logo--title">
                    <NavLink to="/" className="main__logo--link">
                      <img
                        className="main__logo--img"
                        src={logo}
                        alt="logo-img"
                      />
                    </NavLink>
                  </h1>
                </div>
                <div className="main__menu d-none d-lg-block">
                  <nav className="main__menu--navigation">
                    <ul className="main__menu--wrapper d-flex">
                      <li className="main__menu--items">
                        <NavLink to="/" className="main__menu--link">
                          {t.home}
                        </NavLink>
                      </li>
                      <li className="main__menu--items">
                        <NavLink to="/about" className="main__menu--link">
                          {" "}
                          {t.about}{" "}
                        </NavLink>
                      </li>
                      <li className="main__menu--items">
                        <a
                          className="main__menu--link"
                          href="#"
                        >
                          {" "}
                          {t.pages}{" "}
                        </a>
                        <svg
                          className="menu__arrowdown--icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width={10}
                          height={7}
                          viewBox="0 0 12 7.41"
                        >
                          <path
                            d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z"
                            transform="translate(-6 -8.59)"
                            fill="currentColor"
                            opacity="0.7"
                          />
                        </svg>
                        <ul className="sub__menu">
                          <li className="sub__menu--items">
                            <NavLink to="/blog" className="sub__menu--link">
                              {t.blog}
                            </NavLink>
                          </li>
                          <li className="sub__menu--items">
                            <NavLink to="/faq" className="sub__menu--link">
                              {t.faq}
                            </NavLink>
                          </li>
                          <li className="sub__menu--items">
                            <NavLink to="/whyus" className="sub__menu--link">
                              {t.whyus}
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li className="main__menu--items">
                        <NavLink className="main__menu--link" to="/product">
                          {" "}
                          {t.product}{" "}
                        </NavLink>
                      </li>
                      <li className="main__menu--items">
                        <NavLink to="/contact" className="main__menu--link">
                          {" "}
                          {t.contact}{" "}
                        </NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="main__header--right d-flex align-items-center">
                  {/* <a href="tel:+994503862838" className="fs-2">050 386 28 38</a>
                  <a href="">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.27881 4.20703C10.4937 4.20703 12.3218 6.03516 12.3218 8.25C12.3218 10.5 10.4937 12.293 8.27881 12.293C6.02881 12.293 4.23584 10.5 4.23584 8.25C4.23584 6.03516 6.02881 4.20703 8.27881 4.20703ZM8.27881 10.8867C9.72021 10.8867 10.8804 9.72656 10.8804 8.25C10.8804 6.80859 9.72021 5.64844 8.27881 5.64844C6.80225 5.64844 5.64209 6.80859 5.64209 8.25C5.64209 9.72656 6.8374 10.8867 8.27881 10.8867ZM13.4116 4.06641C13.4116 4.59375 12.9897 5.01562 12.4624 5.01562C11.9351 5.01562 11.5132 4.59375 11.5132 4.06641C11.5132 3.53906 11.9351 3.11719 12.4624 3.11719C12.9897 3.11719 13.4116 3.53906 13.4116 4.06641ZM16.0835 5.01562C16.1538 6.31641 16.1538 10.2188 16.0835 11.5195C16.0132 12.7852 15.7319 13.875 14.8179 14.8242C13.9038 15.7383 12.7788 16.0195 11.5132 16.0898C10.2124 16.1602 6.31006 16.1602 5.00928 16.0898C3.74365 16.0195 2.65381 15.7383 1.70459 14.8242C0.790527 13.875 0.509277 12.7852 0.438965 11.5195C0.368652 10.2188 0.368652 6.31641 0.438965 5.01562C0.509277 3.75 0.790527 2.625 1.70459 1.71094C2.65381 0.796875 3.74365 0.515625 5.00928 0.445312C6.31006 0.375 10.2124 0.375 11.5132 0.445312C12.7788 0.515625 13.9038 0.796875 14.8179 1.71094C15.7319 2.625 16.0132 3.75 16.0835 5.01562ZM14.396 12.8906C14.8179 11.8711 14.7124 9.41016 14.7124 8.25C14.7124 7.125 14.8179 4.66406 14.396 3.60938C14.1147 2.94141 13.5874 2.37891 12.9194 2.13281C11.8647 1.71094 9.40381 1.81641 8.27881 1.81641C7.11865 1.81641 4.65771 1.71094 3.63818 2.13281C2.93506 2.41406 2.40771 2.94141 2.12646 3.60938C1.70459 4.66406 1.81006 7.125 1.81006 8.25C1.81006 9.41016 1.70459 11.8711 2.12646 12.8906C2.40771 13.5938 2.93506 14.1211 3.63818 14.4023C4.65771 14.8242 7.11865 14.7188 8.27881 14.7188C9.40381 14.7188 11.8647 14.8242 12.9194 14.4023C13.5874 14.1211 14.1499 13.5938 14.396 12.8906Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a> */}
                  <select
                    name=""
                    id="headerSelect"
                    className="d-none"
                    style={{
                      border: "none",
                      background: "transparent",
                      color: "#000",
                    }}
                    value={language}
                    onChange={handleLanguageChange}
                  >
                    <option value="az" style={{ color: "#000" }}>
                      AZ
                    </option>
                    <option value="en" style={{ color: "#000" }}>
                      EN
                    </option>
                    <option value="ru" style={{ color: "#000" }}>
                      RU
                    </option>
                    <option value="de" style={{ color: "#000" }}>
                      DE
                    </option>
                    <option value="tr" style={{ color: "#000" }}>
                      TR
                    </option>
                  </select>
                  <NavLink to="/calc" className="add__listing--btn solid__btn">
                    <span>{t.order}</span>{" "}
                    <svg
                      width={18}
                      height={18}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 15.9992C12.4111 15.9992 16 12.4105 16 7.99962C16 3.58875 12.411 0 8 0C3.58901 0 0 3.58875 0 7.99962C0 12.4105 3.58901 15.9992 8 15.9992ZM4.19508 7.57155H7.57197V4.19439C7.57197 3.95805 7.76381 3.76636 8 3.76636C8.23634 3.76636 8.42804 3.95821 8.42804 4.19439V7.57155H11.8049C12.0413 7.57155 12.233 7.7634 12.233 7.99958C12.233 8.23592 12.0411 8.42762 11.8049 8.42762H8.42804V11.8046C8.42804 12.041 8.23619 12.2327 8 12.2327C7.76366 12.2327 7.57197 12.0408 7.57197 11.8046V8.42762H4.19508C3.95874 8.42762 3.76704 8.23577 3.76704 7.99958C3.76704 7.76324 3.9586 7.57155 4.19508 7.57155Z"
                        fill="white"
                      />
                    </svg>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* End header area */}
        {/* Start Offcanvas header menu */}
        <div className={`offcanvas__header ${open ? "open" : ""}`}>
          <div className="offcanvas__inner">
            <div className="offcanvas__logo">
              <NavLink to="/" className="offcanvas__logo_link" onClick={() => setOpen(false)}>
                <img src={logo} alt="Logo-img" width={158} height={36} />
              </NavLink>
              <button
                className="offcanvas__close--btn"
                onClick={() => setOpen(false)}
                data-offcanvas
              >
                close
              </button>
            </div>
            <nav className="offcanvas__menu">
              <ul className="offcanvas__menu_ul">
                <li className="offcanvas__menu_li">
                  <NavLink
                    to="/"
                    className="offcanvas__menu_item"
                    onClick={() => setOpen(false)}
                  >
                    {t.home}
                  </NavLink>
                </li>
                <li className="offcanvas__menu_li">
                  <NavLink
                    to="/about"
                    className="offcanvas__menu_item"
                    onClick={() => setOpen(false)}
                  >
                    {t.about}
                  </NavLink>
                </li>
                <li className="offcanvas__menu_li">
                  <NavLink
                    to="/blog"
                    className="offcanvas__menu_item"
                    onClick={() => setOpen(false)}
                  >
                    {t.blog}
                  </NavLink>
                </li>
                <li className="offcanvas__menu_li">
                  <NavLink
                    to="/product"
                    className="offcanvas__menu_item"
                    onClick={() => setOpen(false)}
                  >
                    {t.product}
                  </NavLink>
                </li>
                <li className="offcanvas__menu_li">
                  <NavLink
                    to="/faq"
                    className="offcanvas__menu_item"
                    onClick={() => setOpen(false)}
                  >
                    {t.faq}
                  </NavLink>
                </li>
                <li className="offcanvas__menu_li">
                  <NavLink
                    to="/whyus"
                    className="offcanvas__menu_item"
                    onClick={() => setOpen(false)}
                  >
                    {t.whyus}
                  </NavLink>
                </li>
                
                <li className="offcanvas__menu_li">
                  <NavLink
                    to="/contact"
                    className="offcanvas__menu_item"
                    onClick={() => setOpen(false)}
                  >
                    {t.contact}
                  </NavLink>
                </li>
              </ul>
            </nav>
           <NavLink to="/calc" onClick={() => setOpen(false)}>
           <a className="add__listing--btn offcanvas__listing--btn solid__btn">
              <span>{t.order}</span>{" "}
              <svg
                width={18}
                height={18}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 15.9992C12.4111 15.9992 16 12.4105 16 7.99962C16 3.58875 12.411 0 8 0C3.58901 0 0 3.58875 0 7.99962C0 12.4105 3.58901 15.9992 8 15.9992ZM4.19508 7.57155H7.57197V4.19439C7.57197 3.95805 7.76381 3.76636 8 3.76636C8.23634 3.76636 8.42804 3.95821 8.42804 4.19439V7.57155H11.8049C12.0413 7.57155 12.233 7.7634 12.233 7.99958C12.233 8.23592 12.0411 8.42762 11.8049 8.42762H8.42804V11.8046C8.42804 12.041 8.23619 12.2327 8 12.2327C7.76366 12.2327 7.57197 12.0408 7.57197 11.8046V8.42762H4.19508C3.95874 8.42762 3.76704 8.23577 3.76704 7.99958C3.76704 7.76324 3.9586 7.57155 4.19508 7.57155Z"
                  fill="white"
                />
              </svg>
            </a>
           </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
