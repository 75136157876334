import ultra from "../assets/image/ultra.png";
import lux from "../assets/image/lux.png";
import premier from "../assets/image/premier.png";

import avantaj from "../assets/image/avantaj.png" ;
import eco from "../assets/image/eco.png" ;
import delux from "../assets/image/delux.png" ;
import nasa from "../assets/image/nasa.png" ;
import sila from "../assets/image/sila.png" ;
import safir from "../assets/image/safir.png" ;
const product = [
    {
        id: 0,
        img: ultra,
        title: "Ultra",
        desc: "Kunststoff Scheffer-in Ultra Seriyası, müasir tikinti və inşaat sektorunda istifadə olunan yüksək keyfiyyətli PVC və plastik sistemlər təqdim edən bir məhsul qrupudur. Ultra Seriyası, enerji verimliliyi və estetik xüsusiyyətləri ilə inkişaf etmiş PVC pəncərə və qapı sistemlərini əhatə edir. Bu sistemlər həm yaşayış (rezidensial), həm də ticari layihələrdə istifadə oluna bilər.",
        desc_en: "Kunststoff Scheffer's Ultra Series is a product line that offers high-quality PVC and plastic systems used in modern construction and building sectors. The Ultra Series includes advanced PVC window and door systems with energy efficiency and aesthetic features. These systems can be used in both residential and commercial projects.",
        desc_tr: "Kunststoff Scheffer'in Ultra Serisi, modern inşaat ve yapı sektörlerinde kullanılan yüksek kaliteli PVC ve plastik sistemler sunan bir ürün grubudur. Ultra Serisi, enerji verimliliği ve estetik özelliklere sahip gelişmiş PVC pencere ve kapı sistemlerini içerir. Bu sistemler hem konut hem de ticari projelerde kullanılabilir.",
        desc_ru: "Серия Ultra от Kunststoff Scheffer — это линейка продуктов, предлагающая высококачественные ПВХ и пластиковые системы, используемые в современном строительстве и строительном секторе. Серия Ultra включает в себя современные системы окон и дверей из ПВХ с энергоэффективностью и эстетическими характеристиками. Эти системы могут использоваться как в жилых, так и в коммерческих проектах.",
        desc_de: "Die Ultra-Serie von Kunststoff Scheffer ist eine Produktreihe, die hochwertige PVC- und Plastiksysteme für den modernen Bau- und Konstruktionssektor anbietet. Die Ultra-Serie umfasst fortschrittliche PVC-Fenster- und Türsysteme mit Energieeffizienz und ästhetischen Eigenschaften. Diese Systeme können sowohl in Wohn- als auch in Gewerbeprojekten verwendet werden.",
        table: "<ul><li><b>Yüksək İstilik İzalası:</b> Ultra Seriyası, ısı keçid katsayısı (U-değeri) baxımından çox aşağı dəyərlərə malikdir. Bu, enerji qənaətini təmin edir və komfortu artırır.</li></ul><br/><ul><li><b>Dayanıqlılıq:</b> PVC materiallar, xarici təsirlərə qarşı dayanıqlıdır. Ultraviyole (UV) şüalarına və hava şəraitinə qarşı müqavimət göstərir, bu da uzun müddətli istifadəni təmin edir.</li></ul><br/><ul><li><b>Estetik Dizayn:</b>Müasir memarlıq tələblərinə uyğun müxtəlif rəng və səth variantları təklif edir. Bu sayədə layihələrdə şık və estetik görünüş təmin edir.</li></ul><br/><h3>Ultra Seriyası məhsulları geniş istifadə sahələrinə malikdir:</h3><br/><ul><li><b>Yaşayış Binaları:</b> Pəncərələr və qapılar evlərin enerji səmərəliliyini artırır.</li></ul><br/><ul><li><b>Ticari Binalar:</b> Ofislərdə və mağazalarda estetik və funksional dizayn üçün idealdır.</li></ul><br/><ul><li><b>Sənaye Layihələri: </b> Dayanıklı və enerji effektiv sistemlər, sənaye obyektlərində də istifadə olunur.</li></ul>",
        table_en: "<ul><li><b>High Thermal Insulation:</b> The Ultra Series has very low values for the thermal transmittance coefficient (U-value), ensuring energy savings and increased comfort.</li></ul><br/><ul><li><b>Durability:</b> PVC materials are resistant to external influences. They resist ultraviolet (UV) rays and weather conditions, ensuring long-term use.</li></ul><br/><ul><li><b>Aesthetic Design:</b> Offers various color and surface options that meet modern architectural requirements, providing a stylish and aesthetic appearance for projects.</li></ul><br/><h3>The Ultra Series products have wide applications:</h3><br/><ul><li><b>Residential Buildings:</b> Windows and doors enhance the energy efficiency of homes.</li></ul><br/><ul><li><b>Commercial Buildings:</b> Ideal for aesthetic and functional design in offices and stores.</li></ul><br/><ul><li><b>Industrial Projects:</b> Durable and energy-efficient systems are also used in industrial facilities.</li></ul>",
        table_tr: "<ul><li><b>Yüksek Isı İzalasyonu:</b> Ultra Serisi, ısı geçiş katsayısı (U-değeri) açısından çok düşük değerlere sahiptir. Bu, enerji tasarrufunu sağlar ve konforu artırır.</li></ul><br/><ul><li><b>Dayanıklılık:</b> PVC malzemeler, dış etkilere karşı dayanıklıdır. UV ışınlarına ve hava koşullarına karşı direnç gösterir, bu da uzun süreli kullanım sağlar.</li></ul><br/><ul><li><b>Aestetik Tasarım:</b> Modern mimari gereksinimlere uygun çeşitli renk ve yüzey seçenekleri sunar. Bu sayede projelerde şık ve estetik bir görünüm sağlar.</li></ul><br/><h3>Ultra Serisi ürünlerinin geniş kullanım alanları vardır:</h3><br/><ul><li><b>Konut Binaları:</b> Pencereler ve kapılar evlerin enerji verimliliğini artırır.</li></ul><br/><ul><li><b>Ticari Binalar:</b> Ofislerde ve mağazalarda estetik ve işlevsel tasarım için idealdir.</li></ul><br/><ul><li><b>Endüstriyel Projeler:</b> Dayanıklı ve enerji verimli sistemler, endüstriyel tesislerde de kullanılmaktadır.</li></ul>",
        table_ru: "<ul><li><b>Высокая тепловая изоляция:</b> Серия Ultra имеет очень низкие значения коэффициента теплопередачи (U-значение), что обеспечивает экономию энергии и повышает комфорт.</li></ul><br/><ul><li><b>Долговечность:</b> ПВХ-материалы устойчивы к внешним воздействиям. Они устойчивы к ультрафиолетовым (UV) лучам и погодным условиям, что обеспечивает длительное использование.</li></ul><br/><ul><li><b>Эстетический дизайн:</b> Предлагает различные варианты цвета и поверхности, соответствующие современным архитектурным требованиям, обеспечивая стильный и эстетичный вид для проектов.</li></ul><br/><h3>Продукты серии Ultra имеют широкий спектр применения:</h3><br/><ul><li><b>Жилые здания:</b> Окна и двери повышают энергоэффективность домов.</li></ul><br/><ul><li><b>Коммерческие здания:</b> Идеально подходят для эстетического и функционального дизайна в офисах и магазинах.</li></ul><br/><ul><li><b>Промышленные проекты:</b> Устойчивые и энергоэффективные системы также используются в промышленных объектах.</li></ul>",
        table_de: "<ul><li><b>Hohe Wärmeisolierung:</b> Die Ultra-Serie hat sehr niedrige Werte für den Wärmeübergangskoeffizienten (U-Wert), was Energieeinsparungen und erhöhten Komfort gewährleistet.</li></ul><br/><ul><li><b>Haltbarkeit:</b> PVC-Materialien sind widerstandsfähig gegenüber äußeren Einflüssen. Sie widerstehen ultravioletten (UV) Strahlen und Wetterbedingungen, was eine langfristige Nutzung gewährleistet.</li></ul><br/><ul><li><b>Ästhetisches Design:</b> Bietet verschiedene Farb- und Oberflächenoptionen, die den modernen architektonischen Anforderungen entsprechen und ein stilvolles und ästhetisches Erscheinungsbild für Projekte bieten.</li></ul><br/><h3>Die Produkte der Ultra-Serie haben breite Anwendungen:</h3><br/><ul><li><b>Wohngebäude:</b> Fenster und Türen verbessern die Energieeffizienz von Häusern.</li></ul><br/><ul><li><b>Gewerbliche Gebäude:</b> Ideal für ästhetisches und funktionales Design in Büros und Geschäften.</li></ul><br/><ul><li><b>Industrielle Projekte:</b> Robuste und energieeffiziente Systeme werden auch in industriellen Einrichtungen eingesetzt.</li></ul>"
    },
    {
        id: 1,
        img: lux,
        title: "Lux",
        desc: "Kunststoff Scheffer-in Lux Seriyası, müasir dizayn, enerji səmərəliliyi və yüksək keyfiyyətli materiallarla istehsal olunan məhsul qrupudur. Bu seriya, həm yaşayış, həm də ticari binalar üçün ideal seçimlər təqdim edir. Lux Seriyası, estetik və funksionallığı bir araya gətirən pəncərə və qapı sistemlərini əhatə edir. Bu sistemlər, müasir memarlıq tələblərinə cavab verir və istifadəçilərə geniş imkanlar təqdim edir.",
        desc_en: "Kunststoff Scheffer's Lux Series is a product line produced with modern design, energy efficiency, and high-quality materials. This series offers ideal options for both residential and commercial buildings. The Lux Series includes window and door systems that combine aesthetics and functionality, meeting the demands of modern architecture and providing users with a wide range of options.",
        desc_tr: "Kunststoff Scheffer'in Lux Serisi, modern tasarım, enerji verimliliği ve yüksek kaliteli malzemelerle üretilen bir ürün grubudur. Bu seri, hem konut hem de ticari binalar için ideal seçenekler sunmaktadır. Lux Serisi, estetik ve işlevselliği bir araya getiren pencere ve kapı sistemlerini içerir ve modern mimari gereksinimlere yanıt vererek kullanıcılara geniş imkanlar sunar.",
        desc_ru: "Серия Lux от Kunststoff Scheffer — это линейка продуктов, произведенных с современным дизайном, энергоэффективностью и высококачественными материалами. Эта серия предлагает идеальные варианты как для жилых, так и для коммерческих зданий. Серия Lux включает в себя системы окон и дверей, которые объединяют эстетику и функциональность, соответствуя требованиям современной архитектуры и предлагая пользователям широкий спектр возможностей.",
        desc_de: "Die Lux-Serie von Kunststoff Scheffer ist eine Produktreihe, die mit modernem Design, Energieeffizienz und hochwertigen Materialien hergestellt wird. Diese Serie bietet ideale Optionen sowohl für Wohn- als auch für Gewerbegebäude. Die Lux-Serie umfasst Fenster- und Türsysteme, die Ästhetik und Funktionalität vereinen und den Anforderungen moderner Architektur entsprechen und den Nutzern eine breite Palette von Möglichkeiten bieten.",
        table: "<ul><li><b>Enerji Səmərəliliyi:</b> Lux Seriyası, yüksək izolyasiya qabiliyyəti ilə enerji istehsalını minimuma endirir, bu da qışda istilik itkisinin qarşısını alır.</li></ul><br/><ul><li><b>Estetik Dizayn:</b>Müxtəlif rəng və dizayn variantları ilə, hər bir memarlıq üslubuna uyğunlaşa bilən məhsullar təqdim edir.</li></ul><br/><ul><li><b>Dayanıqlılıq:</b> Yüksək keyfiyyətli PVC materiallardan istehsal olunan bu sistemlər, xarici təsirlərə qarşı davamlıdır.</li></ul><br/><h3>Lux Seriyası məhsulları geniş istifadə sahələrinə malikdir:</h3><br/><ul><li><b>Yaşayış Binaları:</b> Evlərdə estetik görünüş və enerji qənaəti üçün idealdır.</li></ul><br/><ul><li><b>Ticari Binalar:</b> Ofislər, mağazalar və digər ticarət obyektlərində istifadə olunur.</li></ul>",
        table_en: "<ul><li><b>Energy Efficiency:</b> The Lux Series minimizes energy consumption with high insulation capacity, preventing heat loss in winter.</li></ul><br/><ul><li><b>Aesthetic Design:</b> Offers products that can adapt to any architectural style with various color and design options.</li></ul><br/><ul><li><b>Durability:</b> These systems, made from high-quality PVC materials, are resistant to external influences.</li></ul><br/><h3>The Lux Series products have wide applications:</h3><br/><ul><li><b>Residential Buildings:</b> Ideal for aesthetic appearance and energy savings in homes.</li></ul><br/><ul><li><b>Commercial Buildings:</b> Used in offices, stores, and other commercial establishments.</li></ul>",
        table_tr: "<ul><li><b>Enerji Verimliliği:</b> Lux Serisi, yüksek yalıtım kapasitesi ile enerji tüketimini minimize eder, kışın ısı kaybını önler.</li></ul><br/><ul><li><b>Aesthetic Tasarım:</b> Herhangi bir mimari tarza uyum sağlayabilecek çeşitli renk ve tasarım seçenekleri sunar.</li></ul><br/><ul><li><b>Dayanıklılık:</b> Yüksek kaliteli PVC malzemelerden üretilen bu sistemler, dış etkilere karşı dayanıklıdır.</li></ul><br/><h3>Lux Serisi ürünlerinin geniş kullanım alanları vardır:</h3><br/><ul><li><b>Konut Binaları:</b> Evlere estetik görünüm ve enerji tasarrufu sağlamak için idealdir.</li></ul><br/><ul><li><b>Ticari Binalar:</b> Ofislerde, mağazalarda ve diğer ticari kuruluşlarda kullanılır.</li></ul>",
        table_ru: "<ul><li><b>Энергоэффективность:</b> Серия Lux минимизирует потребление энергии с высокой изоляционной способностью, предотвращая потери тепла зимой.</li></ul><br/><ul><li><b>Эстетический дизайн:</b> Предлагает продукты, которые могут адаптироваться к любому архитектурному стилю с различными цветовыми и дизайнерскими вариантами.</li></ul><br/><ul><li><b>Долговечность:</b> Эти системы, изготовленные из высококачественных ПВХ-материалов, устойчивы к внешним воздействиям.</li></ul><br/><h3>Продукты серии Lux имеют широкий спектр применения:</h3><br/><ul><li><b>Жилые здания:</b> Идеальны для эстетического внешнего вида и экономии энергии в домах.</li></ul><br/><ul><li><b>Коммерческие здания:</b> Используются в офисах, магазинах и других коммерческих учреждениях.</li></ul>",
        table_de: "<ul><li><b>Energieeffizienz:</b> Die Lux-Serie minimiert den Energieverbrauch mit hoher Isolierfähigkeit und verhindert Wärmeverluste im Winter.</li></ul><br/><ul><li><b>Ästhetisches Design:</b> Bietet Produkte, die sich an jeden Architekturstil anpassen können, mit verschiedenen Farb- und Designoptionen.</li></ul><br/><ul><li><b>Haltbarkeit:</b> Diese Systeme, die aus hochwertigen PVC-Materialien hergestellt sind, sind widerstandsfähig gegenüber äußeren Einflüssen.</li></ul><br/><h3>Die Produkte der Lux-Serie haben breite Anwendungen:</h3><br/><ul><li><b>Wohngebäude:</b> Ideal für ein ästhetisches Erscheinungsbild und Energieeinsparungen in Wohnhäusern.</li></ul><br/><ul><li><b>Gewerbliche Gebäude:</b> Werden in Büros, Geschäften und anderen gewerblichen Einrichtungen eingesetzt.</li></ul>"
    },
    {
        id: 2,
        img: premier,
        title: "Premier",
        desc: "Kunststoff Scheffer-in Premier Seriyası, müasir tikinti və inşaat sahəsində yüksək keyfiyyətli, enerji səmərəli və estetik pəncərə və qapı sistemləri təqdim edən bir məhsul qrupudur. Bu seriya, həm yaşayış, həm də kommersiya binalarında geniş istifadə oluna bilən sistemlərdən ibarətdir. Premier Seriyası, mükəmməl dizayn və funksionallığı bir araya gətirən məhsullardır. Bu sistemlər, müasir memarlıq tələblərinə uyğun olaraq istehsal olunur və geniş seçim imkanları təqdim edir.",
        desc_en: "Kunststoff Scheffer's Premier Series is a product line that offers high-quality, energy-efficient, and aesthetic window and door systems in modern construction and building sectors. This series consists of systems that can be widely used in both residential and commercial buildings. The Premier Series products combine excellent design and functionality, manufactured to meet modern architectural requirements and offer a wide range of options.",
        desc_tr: "Kunststoff Scheffer'in Premier Serisi, modern inşaat ve yapı sektöründe yüksek kaliteli, enerji verimli ve estetik pencere ve kapı sistemleri sunan bir ürün grubudur. Bu seri, hem konut hem de ticari binalarda geniş kullanılabilen sistemlerden oluşur. Premier Serisi, mükemmel tasarım ve işlevselliği bir araya getiren ürünlerdir. Bu sistemler, modern mimari gereksinimlere uygun olarak üretilmekte ve geniş seçim imkanları sunmaktadır.",
        desc_ru: "Серия Premier от Kunststoff Scheffer — это линейка продуктов, предлагающая высококачественные, энергоэффективные и эстетические системы окон и дверей в современном строительстве и строительном секторе. Эта серия состоит из систем, которые могут широко использоваться как в жилых, так и в коммерческих зданиях. Продукты серии Premier объединяют отличный дизайн и функциональность, производятся с учетом современных архитектурных требований и предлагают широкий выбор.",
        desc_de: "Die Premier-Serie von Kunststoff Scheffer ist eine Produktreihe, die hochwertige, energieeffiziente und ästhetische Fenster- und Türsysteme im modernen Bau- und Konstruktionssektor anbietet. Diese Serie besteht aus Systemen, die sowohl in Wohn- als auch in Gewerbegebäuden weit verbreitet eingesetzt werden können. Die Produkte der Premier-Serie kombinieren hervorragendes Design und Funktionalität, werden unter Berücksichtigung moderner architektonischer Anforderungen hergestellt und bieten eine breite Palette von Optionen.",
        table: "<ul><li><b>Yüksək İstilik İzalası:</b> Premier Seriyası, enerji itkisinin qarşısını alan yüksək izolyasiya xüsusiyyətlərinə malikdir, bu da istilik xərclərini azaltmağa kömək edir.</li></ul><br/><ul><li><b>Dayanıqlı Materiallar:</b> Bu məhsullar, uzun ömürlü və xarici təsirlərə qarşı davamlı yüksək keyfiyyətli PVC materiallardan hazırlanmışdır.</li></ul><br/><ul><li><b>Estetik Görünüş:</b>Müxtəlif dizayn və rəng variantları ilə, hər hansı bir memarlıq üslubuna uyğunlaşa bilən məhsullar təqdim edir.</li></ul><br/><h3>İstifadə Sahələri :</h3><br/><ul><li><b>Yaşayış Binaları:</b> Premier Seriyası, evlərdə enerji səmərəliliyini artırmaq və estetik görünüşü təmin etmək üçün idealdır.</li></ul><br/><ul><li><b>Ticari Binalar:</b> Ofislərdə və ticarət obyektlərində istifadə olunur, müasir və professional görünüş təqdim edir.</li></ul>",
        table_en: "<ul><li><b>High Thermal Insulation:</b> The Premier Series features high insulation properties that prevent energy loss, helping to reduce heating costs.</li></ul><br/><ul><li><b>Durable Materials:</b> These products are made from high-quality PVC materials that are long-lasting and resistant to external influences.</li></ul><br/><ul><li><b>Aesthetic Appearance:</b> Offers products with various design and color options that can adapt to any architectural style.</li></ul><br/><h3>Applications:</h3><br/><ul><li><b>Residential Buildings:</b> The Premier Series is ideal for enhancing energy efficiency and providing an aesthetic appearance in homes.</li></ul><br/><ul><li><b>Commercial Buildings:</b> Used in offices and commercial establishments, offering a modern and professional look.</li></ul>",
        table_tr: "<ul><li><b>Yüksek Isı İzalasyonu:</b> Premier Serisi, enerji kaybını önleyen yüksek yalıtım özelliklerine sahiptir ve bu, ısı maliyetlerini azaltmaya yardımcı olur.</li></ul><br/><ul><li><b>Dayanıklı Malzemeler:</b> Bu ürünler, uzun ömürlü ve dış etkilere karşı dayanıklı yüksek kaliteli PVC malzemelerden üretilmiştir.</li></ul><br/><ul><li><b>Estetik Görünüm:</b> Herhangi bir mimari tarza uyum sağlayabilecek çeşitli tasarım ve renk seçeneklerine sahip ürünler sunar.</li></ul><br/><h3>Uygulamalar:</h3><br/><ul><li><b>Konut Binaları:</b> Premier Serisi, evlerde enerji verimliliğini artırmak ve estetik görünüm sağlamak için idealdir.</li></ul><br/><ul><li><b>Ticari Binalar:</b> Ofislerde ve ticari kuruluşlarda kullanılır, modern ve profesyonel bir görünüm sunar.</li></ul>",
        table_ru: "<ul><li><b>Высокая тепловая изоляция:</b> Серия Premier обладает высокими изоляционными свойствами, которые предотвращают потери энергии, помогая снизить расходы на отопление.</li></ul><br/><ul><li><b>Долговечные материалы:</b> Эти продукты изготовлены из высококачественных ПВХ-материалов, которые долговечны и устойчивы к внешним воздействиям.</li></ul><br/><ul><li><b>Эстетический вид:</b> Предлагает продукты с различными вариантами дизайна и цвета, которые могут адаптироваться к любому архитектурному стилю.</li></ul><br/><h3>Применение:</h3><br/><ul><li><b>Жилые здания:</b> Серия Premier идеально подходит для повышения энергоэффективности и обеспечения эстетического вида в домах.</li></ul><br/><ul><li><b>Коммерческие здания:</b> Используются в офисах и коммерческих учреждениях, предлагая современный и профессиональный вид.</li></ul>",
        table_de: "<ul><li><b>Hohe Wärmeisolierung:</b> Die Premier-Serie bietet hohe Isolierungseigenschaften, die Energieverluste verhindern und helfen, die Heizkosten zu senken.</li></ul><br/><ul><li><b>Haltbare Materialien:</b> Diese Produkte werden aus hochwertigen PVC-Materialien hergestellt, die langlebig und widerstandsfähig gegenüber äußeren Einflüssen sind.</li></ul><br/><ul><li><b>Ästhetisches Erscheinungsbild:</b> Bietet Produkte mit verschiedenen Design- und Farboptionen, die sich an jeden Architekturstil anpassen können.</li></ul><br/><h3>Anwendungen:</h3><br/><ul><li><b>Wohngebäude:</b> Die Premier-Serie ist ideal, um die Energieeffizienz zu steigern und ein ästhetisches Erscheinungsbild in Wohnhäusern zu gewährleisten.</li></ul><br/><ul><li><b>Gewerbliche Gebäude:</b> Wird in Büros und gewerblichen Einrichtungen eingesetzt und bietet ein modernes und professionelles Aussehen.</li></ul>"
    },
    {
        id: 3,
        img: avantaj,
        title: "Avantaj",
        desc: "Avantaj Seriyası PVC pəncərə sistemləri, adətən iqtisadi və funksional xüsusiyyətləri ilə seçilən bir məhsul qrupudur.",
        desc_en: "The Advantage Series PVC window systems are typically a product group distinguished by their economical and functional features.",
        desc_tr: "Avantaj Serisi PVC pencere sistemleri, genellikle ekonomik ve fonksiyonel özellikleri ile öne çıkan bir ürün grubudur.",
        desc_ru: "Серия Avantaj от PVC оконных систем, как правило, представляет собой продуктовую группу, отличающуюся экономическими и функциональными характеристиками.",
        desc_de: "Die Avantaj-Serie von PVC-Fenstersystemen ist typischerweise eine Produktgruppe, die sich durch wirtschaftliche und funktionale Merkmale auszeichnet.",
        
        table: "<ul><li><b>Material Keyfiyyəti:</b> Dayanıqlı PVC materialdan istehsal olunur. Uzun ömürlü və hava şəraitinə dayanıqlıdır.</li></ul><br/><ul><li><b>İstilik və Səs İzolasyonu:</b> Yaxşı istilik izolasyonu təqdim edir, enerji səmərəliliyini artırır. Səs izolasiya xüsusiyyətləri ilə xarici səsi azaldır.</li></ul><br/><ul><li><b>Dizayn Seçimləri:</b> Fərqli rəng və model seçimləri ilə müxtəlif dekorasiya stillərinə uyğunlaşa bilər. Müasir və şık bir görünüş təqdim edir.</li></ul><br/><h3>İstifadə Sahələri :</h3><br/><ul><li><b>Məişət İstifadəsi:</b> Ev Pəncərələri: Müxtəlif ev tiplərində, məsələn, mənzillərdə, villa və ya bağ evlərində istifadə olunur.</li></ul><br/><ul><li><b>İstirahət və İdman Obyektləri:</b> İdman Salonları: İdman mərkəzlərinin pəncərə və qapıları üçün istifadəyə uyğundur. Restoranlarda gözəl görünüş və müştəri rahatlığı üçün açıq yerlərdə istifadə olunur.</li></ul>",
        
        table_en: "<ul><li><b>Material Quality:</b> Made from durable PVC material. Long-lasting and weather-resistant.</li></ul><br/><ul><li><b>Heat and Sound Insulation:</b> Provides good heat insulation, enhancing energy efficiency. Sound insulation features reduce external noise.</li></ul><br/><ul><li><b>Design Options:</b> Can adapt to various decorative styles with different color and model options. Offers a modern and stylish appearance.</li></ul><br/><h3>Applications :</h3><br/><ul><li><b>Residential Use:</b> Window systems for various types of homes, such as apartments, villas, or summer houses.</li></ul><br/><ul><li><b>Recreational and Sports Facilities:</b> Sports Halls: Suitable for the windows and doors of sports centers. Used in restaurants for a pleasant appearance and customer comfort in open areas.</li></ul>",
        
        table_tr: "<ul><li><b>Malzeme Kalitesi:</b> Dayanıklı PVC malzemeden üretilir. Uzun ömürlü ve hava koşullarına dayanıklıdır.</li></ul><br/><ul><li><b>Isı ve Ses Yalıtımı:</b> İyi bir ısı yalıtımı sunarak enerji verimliliğini artırır. Ses yalıtım özellikleri dışarıdaki sesi azaltır.</li></ul><br/><ul><li><b>Tasarım Seçenekleri:</b> Farklı renk ve model seçenekleri ile çeşitli dekorasyon stillerine uyum sağlar. Modern ve şık bir görünüm sunar.</li></ul><br/><h3>Kullanım Alanları:</h3><br/><ul><li><b>Konut Kullanımı:</b> Farklı ev türleri için, örneğin apartmanlarda, villalarda veya yazlık evlerde kullanılan pencere sistemleri.</li></ul><br/><ul><li><b>Rekreasyon ve Spor Tesisleri:</b> Spor Salonları: Spor merkezlerinin pencere ve kapıları için uygundur. Restoranlarda açık alanlarda hoş bir görünüm ve müşteri konforu için kullanılır.</li></ul>",
        
        table_ru: "<ul><li><b>Качество Материала:</b> Изготавливаются из прочного ПВХ материала. Долговечны и устойчивы к погодным условиям.</li></ul><br/><ul><li><b>Тепло- и Шумоизоляция:</b> Обеспечивает хорошую теплоизоляцию, повышая энергоэффективность. Шумоизоляционные характеристики уменьшают внешний шум.</li></ul><br/><ul><li><b>Дизайнерские Опции:</b> Могут адаптироваться к различным декоративным стилям с различными вариантами цвета и модели. Предлагает современный и стильный внешний вид.</li></ul><br/><h3>Области Применения:</h3><br/><ul><li><b>Жилое Использование:</b> Оконные системы для различных типов жилья, таких как квартиры, виллы или дачи.</li></ul><br/><ul><li><b>Рекреационные и Спортивные Объекты:</b> Спортивные Залы: Подходят для окон и дверей спортивных центров. Используются в ресторанах для приятного вида и комфорта клиентов в открытых местах.</li></ul>",
        
        table_de: "<ul><li><b>Materialqualität:</b> Hergestellt aus strapazierfähigem PVC-Material. Langlebig und wetterbeständig.</li></ul><br/><ul><li><b>Wärme- und Schallisolation:</b> Bietet gute Wärmeisolierung und steigert die Energieeffizienz. Schallisolationseigenschaften reduzieren äußeren Lärm.</li></ul><br/><ul><li><b>Designmöglichkeiten:</b> Kann sich mit verschiedenen Farb- und Modelloptionen an verschiedene Dekorationsstile anpassen. Bietet ein modernes und stilvolles Aussehen.</li></ul><br/><h3>Anwendungsbereiche:</h3><br/><ul><li><b>Privater Gebrauch:</b> Fenstersysteme für verschiedene Haustypen, wie z. B. Wohnungen, Villen oder Ferienhäuser.</li></ul><br/><ul><li><b>Freizeit- und Sporteinrichtungen:</b> Sporthallen: Geeignet für Fenster und Türen von Sportzentren. Wird in Restaurants für ein angenehmes Aussehen und Kundenkomfort in Außenbereichen verwendet.</li></ul>",
    },
    {
        id: 4,
        img: eco,
        title: "Eco",
        desc: "Ekoloji Ultra Seriyası, ekoloji dostu PVC pəncərə sistemləri təqdim edən bir məhsul qrupudur. Bu seriya, enerji səmərəliliyi və ekoloji balansı təmin etmək məqsədilə hazırlanmışdır.",
        desc_en: "The Eco Ultra Series is a product group that offers eco-friendly PVC window systems. This series is designed to ensure energy efficiency and ecological balance.",
        desc_tr: "Ekolojik Ultra Serisi, çevre dostu PVC pencere sistemleri sunan bir ürün grubudur. Bu seri, enerji verimliliğini ve ekolojik dengeyi sağlamak amacıyla tasarlanmıştır.",
        desc_ru: "Экологическая Ультра Серия — это группа продуктов, предлагающая экологически чистые системы ПВХ окон. Эта серия разработана для обеспечения энергоэффективности и экологического баланса.",
        desc_de: "Die Eco Ultra Serie ist eine Produktgruppe, die umweltfreundliche PVC-Fenstersysteme anbietet. Diese Serie wurde entwickelt, um Energieeffizienz und ökologisches Gleichgewicht zu gewährleisten.",
        
        table: "<ul><li><b>Material Keyfiyyəti:</b> Ekoloji standartlara uyğun, dayanıqlı PVC materialdan istehsal olunur. Uzun ömürlü və keyfiyyətlidir.</li></ul><br/><ul><li><b>İstilik və Səs İzolasyonu:</b> Yüksək istilik izolasyonu təqdim edir, enerji sərfiyyatını azaldır. Səs izolasiya xüsusiyyətləri ilə xarici səsi minimuma endirir.</li></ul><br/><ul><li><b>Dizayn Seçimləri:</b> Müxtəlif rəng və dizayn seçimləri ilə müasir memarlıq tələblərinə cavab verir. Ekoloji tələblərə uyğun materiallarla hazırlanmışdır.</li></ul><br/><h3>İstifadə Sahələri :</h3><br/><ul><li><b>Məişət İstifadəsi:</b> Ev pəncərələri üçün, məsələn, mənzillərdə, villalarda və bağ evlərində istifadə olunur.</li></ul><br/><ul><li><b>Ticarət Obyektləri:</b> Restoranlar, ofis binaları və digər kommersiya obyektlərində geniş yayılmışdır.</li></ul><br/><ul><li><b>İstirahət Obyektləri:</b> İdman kompleksləri və otellərdə müştəri rahatlığını artırmaq üçün istifadə edilir.</li></ul>",
        
        table_en: "<ul><li><b>Material Quality:</b> Made from eco-friendly, durable PVC material. Long-lasting and high-quality.</li></ul><br/><ul><li><b>Heat and Sound Insulation:</b> Provides high heat insulation, reducing energy consumption. Sound insulation features minimize external noise.</li></ul><br/><ul><li><b>Design Options:</b> Meets modern architectural requirements with various color and design options. Made from materials that comply with ecological standards.</li></ul><br/><h3>Applications :</h3><br/><ul><li><b>Residential Use:</b> Used for window systems in various homes, such as apartments, villas, and summer houses.</li></ul><br/><ul><li><b>Commercial Properties:</b> Widely used in restaurants, office buildings, and other commercial spaces.</li></ul><br/><ul><li><b>Recreational Facilities:</b> Utilized in sports complexes and hotels to enhance customer comfort.</li></ul>",
        
        table_tr: "<ul><li><b>Malzeme Kalitesi:</b> Çevre dostu, dayanıklı PVC malzemeden üretilir. Uzun ömürlü ve kaliteli.</li></ul><br/><ul><li><b>Isı ve Ses Yalıtımı:</b> Yüksek ısı yalıtımı sunarak enerji tüketimini azaltır. Ses yalıtım özellikleri dışarıdaki sesi minimize eder.</li></ul><br/><ul><li><b>Tasarım Seçenekleri:</b> Farklı renk ve tasarım seçenekleri ile modern mimari gereksinimlere cevap verir. Ekolojik standartlara uygun malzemelerle üretilmiştir.</li></ul><br/><h3>Kullanım Alanları:</h3><br/><ul><li><b>Konut Kullanımı:</b> Farklı evlerde, örneğin apartmanlarda, villalarda ve yazlık evlerde kullanılır.</li></ul><br/><ul><li><b>Ticari Özellikler:</b> Restoranlar, ofis binaları ve diğer ticari alanlarda yaygın olarak kullanılır.</li></ul><br/><ul><li><b>Rekreasyon Alanları:</b> Spor komplekslerinde ve otellerde müşteri konforunu artırmak için kullanılır.</li></ul>",
        
        table_ru: "<ul><li><b>Качество Материала:</b> Изготавливаются из экологически чистого, прочного ПВХ материала. Долговечны и высокого качества.</li></ul><br/><ul><li><b>Тепло- и Шумоизоляция:</b> Обеспечивает высокую теплоизоляцию, снижая потребление энергии. Шумоизоляционные характеристики минимизируют внешний шум.</li></ul><br/><ul><li><b>Дизайнерские Опции:</b> Соответствует современным архитектурным требованиям с различными вариантами цветов и дизайна. Изготовлено из материалов, соответствующих экологическим стандартам.</li></ul><br/><h3>Области Применения:</h3><br/><ul><li><b>Жилое Использование:</b> Используется для оконных систем в различных домах, таких как квартиры, виллы и дачи.</li></ul><br/><ul><li><b>Коммерческие Объекты:</b> Широко используются в ресторанах, офисных зданиях и других коммерческих пространствах.</li></ul><br/><ul><li><b>Рекреационные Установки:</b> Используются в спортивных комплексах и отелях для повышения комфорта клиентов.</li></ul>",
        
        table_de: "<ul><li><b>Materialqualität:</b> Hergestellt aus umweltfreundlichem, strapazierfähigem PVC-Material. Langlebig und von hoher Qualität.</li></ul><br/><ul><li><b>Wärme- und Schallisolation:</b> Bietet hohe Wärmeisolierung und reduziert den Energieverbrauch. Schallisolationseigenschaften minimieren äußeren Lärm.</li></ul><br/><ul><li><b>Designmöglichkeiten:</b> Entspricht modernen architektonischen Anforderungen mit verschiedenen Farb- und Designoptionen. Hergestellt aus Materialien, die den ökologischen Standards entsprechen.</li></ul><br/><h3>Anwendungsbereiche:</h3><br/><ul><li><b>Privater Gebrauch:</b> Verwendet für Fenstersysteme in verschiedenen Wohnformen, wie z.B. Wohnungen, Villen und Ferienhäuser.</li></ul><br/><ul><li><b>Kaufmännische Eigenschaften:</b> Weit verbreitet in Restaurants, Bürogebäuden und anderen gewerblichen Räumen.</li></ul><br/><ul><li><b>Freizeit- und Sporteinrichtungen:</b> Eingesetzt in Sportkomplexen und Hotels zur Verbesserung des Kundenkomforts.</li></ul>",
    },
    {
        id: 5,
        img: delux,
        title: "Delüx",
        desc: "Delüx Seriyası, premium keyfiyyətli PVC pəncərə sistemləri təqdim edən bir məhsul qrupudur. Bu seriya, lüks dizayn və mükəmməl funksionallıq ilə seçilir.",
        desc_en: "The Deluxe Series is a product group that offers premium quality PVC window systems. This series is distinguished by its luxurious design and excellent functionality.",
        desc_tr: "Delüx Serisi, premium kalitede PVC pencere sistemleri sunan bir ürün grubudur. Bu seri, lüks tasarım ve mükemmel işlevsellik ile öne çıkmaktadır.",
        desc_ru: "Серия Deluxe — это группа продуктов, предлагающая высококачественные ПВХ оконные системы. Эта серия выделяется своим роскошным дизайном и отличной функциональностью.",
        desc_de: "Die Deluxe-Serie ist eine Produktgruppe, die hochwertige PVC-Fenstersysteme anbietet. Diese Serie zeichnet sich durch ihr luxuriöses Design und hervorragende Funktionalität aus.",
        
        table: "<ul><li><b>Material Keyfiyyəti:</b> Yüksək keyfiyyətli PVC materialdan istehsal olunur. Uzun ömürlü və estetik görünüşü ilə seçilir.</li></ul><br/><ul><li><b>İstilik və Səs İzolasyonu:</b> Yüksək istilik və səs izolyasiyası ilə enerji səmərəliliyini artırır. Xarici səsin azalmasına kömək edir.</li></ul><br/><ul><li><b>Dizayn Seçimləri:</b> Müxtəlif dizayn və rəng seçimləri ilə müasir memarlıq tələblərinə uyğunlaşa bilər. Lüks bir görünüş təqdim edir.</li></ul><br/><h3>İstifadə Sahələri :</h3><br/><ul><li><b>Məişət İstifadəsi:</b> Villa və müasir mənzillərdə pəncərələr üçün ideal seçimdir.</li></ul><br/><ul><li><b>İctimai Obyektlər:</b> Otel və restoranlarda müasir dizayn və müştəri komfortu üçün istifadə olunur.</li></ul><br/><ul><li><b>İş Obyektləri:</b> Ofis binalarında lüks görünüş təmin edir.</li></ul>",
        
        table_en: "<ul><li><b>Material Quality:</b> Made from high-quality PVC material. Long-lasting and aesthetically appealing.</li></ul><br/><ul><li><b>Heat and Sound Insulation:</b> Increases energy efficiency with high heat and sound insulation. Helps to reduce external noise.</li></ul><br/><ul><li><b>Design Options:</b> Adapts to modern architectural requirements with various design and color options. Offers a luxurious appearance.</li></ul><br/><h3>Applications :</h3><br/><ul><li><b>Residential Use:</b> An ideal choice for windows in villas and modern apartments.</li></ul><br/><ul><li><b>Public Properties:</b> Used in hotels and restaurants for modern design and customer comfort.</li></ul><br/><ul><li><b>Business Properties:</b> Provides a luxurious look in office buildings.</li></ul>",
        
        table_tr: "<ul><li><b>Malzeme Kalitesi:</b> Yüksek kaliteli PVC malzemeden üretilir. Uzun ömürlü ve estetik görünümü ile dikkat çeker.</li></ul><br/><ul><li><b>Isı ve Ses Yalıtımı:</b> Yüksek ısı ve ses yalıtımı ile enerji verimliliğini artırır. Dışarıdaki sesi azaltmaya yardımcı olur.</li></ul><br/><ul><li><b>Tasarım Seçenekleri:</b> Farklı tasarım ve renk seçenekleri ile modern mimari gereksinimlere uyum sağlar. Lüks bir görünüm sunar.</li></ul><br/><h3>Kullanım Alanları:</h3><br/><ul><li><b>Konut Kullanımı:</b> Villa ve modern daireler için ideal bir seçimdir.</li></ul><br/><ul><li><b>Kamu Binaları:</b> Otel ve restoranlarda modern tasarım ve müşteri konforu için kullanılır.</li></ul><br/><ul><li><b>İş Yerleri:</b> Ofis binalarında lüks bir görünüm sağlar.</li></ul>",
        
        table_ru: "<ul><li><b>Качество Материала:</b> Изготавливаются из высококачественного ПВХ материала. Долговечны и эстетически привлекательны.</li></ul><br/><ul><li><b>Тепло- и Шумоизоляция:</b> Повышает энергоэффективность с высокой тепло- и шумоизоляцией. Помогает снизить внешний шум.</li></ul><br/><ul><li><b>Дизайнерские Опции:</b> Адаптируется к современным архитектурным требованиям с различными вариантами дизайна и цвета. Предлагает роскошный вид.</li></ul><br/><h3>Области Применения:</h3><br/><ul><li><b>Жилое Использование:</b> Идеальный выбор для окон в виллах и современных квартирах.</li></ul><br/><ul><li><b>Общественные Объекты:</b> Используется в отелях и ресторанах для современного дизайна и комфорта клиентов.</li></ul><br/><ul><li><b>Коммерческие Свойства:</b> Обеспечивает роскошный вид в офисных зданиях.</li></ul>",
        
        table_de: "<ul><li><b>Materialqualität:</b> Hergestellt aus hochwertigem PVC-Material. Langlebig und ästhetisch ansprechend.</li></ul><br/><ul><li><b>Wärme- und Schallisolation:</b> Erhöht die Energieeffizienz mit hoher Wärme- und Schallisolation. Hilft, äußeren Lärm zu reduzieren.</li></ul><br/><ul><li><b>Designmöglichkeiten:</b> Passt sich modernen architektonischen Anforderungen mit verschiedenen Design- und Farboptionen an. Bietet ein luxuriöses Aussehen.</li></ul><br/><h3>Anwendungsbereiche:</h3><br/><ul><li><b>Privater Gebrauch:</b> Eine ideale Wahl für Fenster in Villen und modernen Wohnungen.</li></ul><br/><ul><li><b>Öffentliche Einrichtungen:</b> Verwendet in Hotels und Restaurants für modernes Design und Kundenkomfort.</li></ul><br/><ul><li><b>Geschäftliche Eigenschaften:</b> Bietet einen luxuriösen Look in Bürogebäuden.</li></ul>",
    },
    {
        id: 6,
        img: nasa,
        title: "Nasa",
        desc: "Nasa Seriyası, müasir texnologiyaların tətbiqi ilə hazırlanmış yüksək keyfiyyətli PVC pəncərə sistemlərini təqdim edir. Bu seriya, enerji səmərəliliyi və mükəmməl dizaynı ilə seçilir.",
        desc_en: "The Nasa Series offers high-quality PVC window systems designed using modern technologies. This series is distinguished by its energy efficiency and excellent design.",
        desc_tr: "Nasa Serisi, modern teknolojilerin uygulanmasıyla hazırlanmış yüksek kaliteli PVC pencere sistemleri sunar. Bu seri, enerji verimliliği ve mükemmel tasarımı ile öne çıkmaktadır.",
        desc_ru: "Серия Nasa предлагает высококачественные ПВХ оконные системы, разработанные с использованием современных технологий. Эта серия выделяется своей энергоэффективностью и отличным дизайном.",
        desc_de: "Die Nasa-Serie bietet hochwertige PVC-Fenstersysteme, die mit modernen Technologien entwickelt wurden. Diese Serie zeichnet sich durch ihre Energieeffizienz und hervorragendes Design aus.",
        
        table: "<ul><li><b>Material Keyfiyyəti:</b> Yüksək keyfiyyətli PVC materialdan istehsal olunur. Uzun ömürlü və davamlıdır.</li></ul><br/><ul><li><b>İstilik və Səs İzolasyonu:</b> İstilik izolyasiyasını artırır və enerji sərfini azaldır. Xarici səsin azalmasına kömək edir.</li></ul><br/><ul><li><b>Dizayn Seçimləri:</b> Müasir dizayn və müxtəlif rəng seçimləri ilə fərqli memarlıq üslublarına uyum sağlar.</li></ul><br/><h3>İstifadə Sahələri :</h3><br/><ul><li><b>Məişət İstifadəsi:</b> Ev pəncərələri, villa və bağ evləri üçün ideal seçimdir.</li></ul><br/><ul><li><b>İctimai Obyektlər:</b> Ofis binaları və ticarət mərkəzlərində istifadə olunur.</li></ul><br/><ul><li><b>İdman Obyektləri:</b> İdman kompleksləri üçün uyğun pəncərə sistemləridir.</li></ul>",
        
        table_en: "<ul><li><b>Material Quality:</b> Made from high-quality PVC material. Long-lasting and durable.</li></ul><br/><ul><li><b>Heat and Sound Insulation:</b> Enhances heat insulation and reduces energy consumption. Helps to reduce external noise.</li></ul><br/><ul><li><b>Design Options:</b> Adapts to different architectural styles with modern designs and various color options.</li></ul><br/><h3>Applications :</h3><br/><ul><li><b>Residential Use:</b> An ideal choice for window systems in houses, villas, and summer homes.</li></ul><br/><ul><li><b>Public Properties:</b> Used in office buildings and shopping centers.</li></ul><br/><ul><li><b>Sports Facilities:</b> Suitable window systems for sports complexes.</li></ul>",
        
        table_tr: "<ul><li><b>Malzeme Kalitesi:</b> Yüksek kaliteli PVC malzemeden üretilir. Uzun ömürlü ve dayanıklıdır.</li></ul><br/><ul><li><b>Isı ve Ses Yalıtımı:</b> Isı yalıtımını artırır ve enerji tüketimini azaltır. Dışarıdaki sesi azaltmaya yardımcı olur.</li></ul><br/><ul><li><b>Tasarım Seçenekleri:</b> Modern tasarımlar ve çeşitli renk seçenekleri ile farklı mimari stillere uyum sağlar.</li></ul><br/><h3>Kullanım Alanları:</h3><br/><ul><li><b>Konut Kullanımı:</b> Evler, villalar ve yazlıklar için ideal bir seçimdir.</li></ul><br/><ul><li><b>Kamu Binaları:</b> Ofis binalarında ve alışveriş merkezlerinde kullanılır.</li></ul><br/><ul><li><b>Spor Tesisleri:</b> Spor kompleksleri için uygun pencere sistemleridir.</li></ul>",
        
        table_ru: "<ul><li><b>Качество Материала:</b> Изготавливаются из высококачественного ПВХ материала. Долговечны и устойчивы.</li></ul><br/><ul><li><b>Тепло- и Шумоизоляция:</b> Увеличивает теплоизоляцию и снижает энергозатраты. Помогает уменьшить внешний шум.</li></ul><br/><ul><li><b>Дизайнерские Опции:</b> Адаптируется к различным архитектурным стилям с современными дизайнами и различными цветами.</li></ul><br/><h3>Области Применения:</h3><br/><ul><li><b>Жилое Использование:</b> Идеальный выбор для окон в домах, виллах и дачах.</li></ul><br/><ul><li><b>Общественные Объекты:</b> Используется в офисных зданиях и торговых центрах.</li></ul><br/><ul><li><b>Спортивные Объекты:</b> Подходящие оконные системы для спортивных комплексов.</li></ul>",
        
        table_de: "<ul><li><b>Materialqualität:</b> Hergestellt aus hochwertigem PVC-Material. Langlebig und strapazierfähig.</li></ul><br/><ul><li><b>Wärme- und Schallisolation:</b> Erhöht die Wärmeisolierung und reduziert den Energieverbrauch. Hilft, äußeren Lärm zu reduzieren.</li></ul><br/><ul><li><b>Designmöglichkeiten:</b> Passt sich mit modernen Designs und verschiedenen Farboptionen an unterschiedliche architektonische Stile an.</li></ul><br/><h3>Anwendungsbereiche:</h3><br/><ul><li><b>Privater Gebrauch:</b> Eine ideale Wahl für Fenstersysteme in Häusern, Villen und Ferienhäusern.</li></ul><br/><ul><li><b>Öffentliche Einrichtungen:</b> Verwendet in Bürogebäuden und Einkaufszentren.</li></ul><br/><ul><li><b>Sporteinrichtungen:</b> Geeignete Fenstersysteme für Sportkomplexe.</li></ul>",
    },
    {
        id: 7,
        img: sila,
        title: "Sıla",
        desc: "Sıla Seriyası, müasir dizayn və yüksək performansla hazırlanmış PVC pəncərə sistemlərini təqdim edir. Bu seriya, enerji səmərəliliyi və dayanıqlığı ilə fərqlənir.",
        desc_en: "The Sıla Series offers PVC window systems designed with modern aesthetics and high performance. This series is distinguished by its energy efficiency and durability.",
        desc_tr: "Sıla Serisi, modern tasarıma ve yüksek performansa sahip PVC pencere sistemleri sunar. Bu seri, enerji verimliliği ve dayanıklılığı ile öne çıkmaktadır.",
        desc_ru: "Серия Sıla предлагает ПВХ оконные системы, разработанные с современным дизайном и высокой производительностью. Эта серия выделяется своей энергоэффективностью и прочностью.",
        desc_de: "Die Sıla-Serie bietet PVC-Fenstersysteme, die mit modernem Design und hoher Leistung entwickelt wurden. Diese Serie zeichnet sich durch ihre Energieeffizienz und Langlebigkeit aus.",
    
        table: "<ul><li><b>Material Keyfiyyəti:</b> Yüksək keyfiyyətli PVC materialdan istehsal olunur. Hava şəraitinə dayanıqlıdır.</li></ul><br/><ul><li><b>İstilik və Səs İzolasyonu:</b> İstilik izolyasiyasını artıraraq enerji sərfini azaldır. Səs izolyasiyası xarici səsi minimuma endirir.</li></ul><br/><ul><li><b>Dizayn Seçimləri:</b> Fərqli rəng və model seçimləri ilə müasir interyerlərə uyum sağlar.</li></ul><br/><h3>İstifadə Sahələri :</h3><br/><ul><li><b>Məişət İstifadəsi:</b> Ev pəncərələri, bağ evləri və yazlıq evlər üçün idealdır.</li></ul><br/><ul><li><b>Ticarət Obyektləri:</b> Ofis binaları və ticarət mərkəzlərində geniş istifadə olunur.</li></ul><br/><ul><li><b>İdman Kompleksləri:</b> İdman salonları və digər idman obyektləri üçün uyğundur.</li></ul>",
    
        table_en: "<ul><li><b>Material Quality:</b> Made from high-quality PVC material. Weather-resistant and durable.</li></ul><br/><ul><li><b>Heat and Sound Insulation:</b> Enhances heat insulation, reducing energy consumption. Sound insulation minimizes external noise.</li></ul><br/><ul><li><b>Design Options:</b> Adapts to modern interiors with various color and model options.</li></ul><br/><h3>Applications :</h3><br/><ul><li><b>Residential Use:</b> Ideal for window systems in houses, summer homes, and vacation properties.</li></ul><br/><ul><li><b>Commercial Properties:</b> Widely used in office buildings and shopping centers.</li></ul><br/><ul><li><b>Sports Complexes:</b> Suitable for sports halls and other sports facilities.</li></ul>",
    
        table_tr: "<ul><li><b>Malzeme Kalitesi:</b> Yüksek kaliteli PVC malzemeden üretilir. Hava koşullarına dayanıklıdır.</li></ul><br/><ul><li><b>Isı ve Ses Yalıtımı:</b> Isı yalıtımını artırarak enerji tüketimini azaltır. Ses yalıtımı dışarıdaki sesi minimuma indirir.</li></ul><br/><ul><li><b>Tasarım Seçenekleri:</b> Farklı renk ve model seçenekleri ile modern iç mekanlara uyum sağlar.</li></ul><br/><h3>Kullanım Alanları:</h3><br/><ul><li><b>Konut Kullanımı:</b> Evlerde, yazlık evlerde ve tatil mülklerinde ideal pencere sistemleri.</li></ul><br/><ul><li><b>Ticari Mülkler:</b> Ofis binalarında ve alışveriş merkezlerinde yaygın olarak kullanılır.</li></ul><br/><ul><li><b>Spor Kompleksleri:</b> Spor salonları ve diğer spor tesisleri için uygundur.</li></ul>",
    
        table_ru: "<ul><li><b>Качество Материала:</b> Изготавливаются из высококачественного ПВХ материала. Устойчивы к погодным условиям и долговечны.</li></ul><br/><ul><li><b>Тепло- и Шумоизоляция:</b> Повышает теплоизоляцию, снижая потребление энергии. Шумоизоляция минимизирует внешний шум.</li></ul><br/><ul><li><b>Дизайнерские Опции:</b> Адаптируется к современным интерьерам с различными вариантами цвета и модели.</li></ul><br/><h3>Области Применения:</h3><br/><ul><li><b>Жилое Использование:</b> Идеальные оконные системы для домов, дач и загородных объектов.</li></ul><br/><ul><li><b>Коммерческая Недвижимость:</b> Широко используется в офисных зданиях и торговых центрах.</li></ul><br/><ul><li><b>Спортивные Комплексы:</b> Подходят для спортивных залов и других спортивных объектов.</li></ul>",
    
        table_de: "<ul><li><b>Materialqualität:</b> Hergestellt aus hochwertigem PVC-Material. Wetterbeständig und langlebig.</li></ul><br/><ul><li><b>Wärme- und Schallisolation:</b> Verbessert die Wärmeisolierung und reduziert den Energieverbrauch. Schallisolation minimiert äußeren Lärm.</li></ul><br/><ul><li><b>Designmöglichkeiten:</b> Passt sich mit verschiedenen Farb- und Modelloptionen an moderne Innenräume an.</li></ul><br/><h3>Anwendungsbereiche:</h3><br/><ul><li><b>Privater Gebrauch:</b> Ideale Fensteranlagen für Häuser, Ferienhäuser und Urlaubsimmobilien.</li></ul><br/><ul><li><b>Gewerbeimmobilien:</b> Wird häufig in Bürogebäuden und Einkaufszentren eingesetzt.</li></ul><br/><ul><li><b>Sportanlagen:</b> Geeignet für Sporthallen und andere Sporteinrichtungen.</li></ul>",
    },
    {
        id: 8,
        img: safir,
        title: "Safir",
        desc: "Safir Seriyası, yüksək keyfiyyətli PVC pəncərə sistemləri ilə müasir dizaynı birləşdirir. Bu seriya, enerji səmərəliliyi və uzun ömürlü olması ilə tanınır.",
        desc_en: "The Safir Series combines high-quality PVC window systems with modern design. This series is recognized for its energy efficiency and durability.",
        desc_tr: "Safir Serisi, yüksek kaliteli PVC pencere sistemlerini modern tasarımla birleştirir. Bu seri, enerji verimliliği ve dayanıklılığı ile tanınmaktadır.",
        desc_ru: "Серия Safir объединяет высококачественные ПВХ оконные системы с современным дизайном. Эта серия известна своей энергоэффективностью и долговечностью.",
        desc_de: "Die Safir-Serie kombiniert hochwertige PVC-Fenstersysteme mit modernem Design. Diese Serie zeichnet sich durch ihre Energieeffizienz und Langlebigkeit aus.",
    
        table: "<ul><li><b>Material Keyfiyyəti:</b> Yüksək keyfiyyətli PVC materialdan istehsal olunur. Hava şəraitinə və aşınmaya qarşı dayanıqlıdır.</li></ul><br/><ul><li><b>İstilik və Səs İzolasyonu:</b> Yüksək istilik izolasyonu təmin edir, enerji xərclərini azaldır. Xarici səsi minimuma endirir.</li></ul><br/><ul><li><b>Dizayn Seçimləri:</b> Fərqli rəng və modellərlə müasir interyerlər üçün idealdır.</li></ul><br/><h3>İstifadə Sahələri :</h3><br/><ul><li><b>Məişət İstifadəsi:</b> Ev pəncərələri, villa və bağ evləri üçün uygundur.</li></ul><br/><ul><li><b>Ticarət Obyektləri:</b> Ofis binaları, ticarət mərkəzləri və restoranlarda geniş istifadə olunur.</li></ul><br/><ul><li><b>İdman Kompleksləri:</b> İdman mərkəzlərinin pəncərələri üçün ideal seçimdir.</li></ul>",
    
        table_en: "<ul><li><b>Material Quality:</b> Made from high-quality PVC material. Resistant to weather conditions and wear.</li></ul><br/><ul><li><b>Heat and Sound Insulation:</b> Provides high heat insulation, reducing energy costs. Minimizes external noise.</li></ul><br/><ul><li><b>Design Options:</b> Ideal for modern interiors with various color and model options.</li></ul><br/><h3>Applications :</h3><br/><ul><li><b>Residential Use:</b> Suitable for window systems in homes, villas, and summer houses.</li></ul><br/><ul><li><b>Commercial Properties:</b> Widely used in office buildings, shopping centers, and restaurants.</li></ul><br/><ul><li><b>Sports Complexes:</b> An ideal choice for windows in sports centers.</li></ul>",
    
        table_tr: "<ul><li><b>Malzeme Kalitesi:</b> Yüksek kaliteli PVC malzemeden üretilir. Hava koşullarına ve aşınmaya dayanıklıdır.</li></ul><br/><ul><li><b>Isı ve Ses Yalıtımı:</b> Yüksek ısı yalıtımı sunarak enerji maliyetlerini azaltır. Dışarıdaki sesi minimuma indirir.</li></ul><br/><ul><li><b>Tasarım Seçenekleri:</b> Farklı renk ve model seçenekleri ile modern iç mekanlar için idealdir.</li></ul><br/><h3>Kullanım Alanları:</h3><br/><ul><li><b>Konut Kullanımı:</b> Ev pencereleri, villa ve yazlık evler için uygundur.</li></ul><br/><ul><li><b>Ticari Mülkler:</b> Ofis binalarında, alışveriş merkezlerinde ve restoranlarda yaygın olarak kullanılır.</li></ul><br/><ul><li><b>Spor Kompleksleri:</b> Spor merkezlerindeki pencereler için ideal bir seçimdir.</li></ul>",
    
        table_ru: "<ul><li><b>Качество Материала:</b> Изготавливаются из высококачественного ПВХ материала. Устойчивы к погодным условиям и износу.</li></ul><br/><ul><li><b>Тепло- и Шумоизоляция:</b> Обеспечивает высокую теплоизоляцию, снижая затраты на энергоресурсы. Минимизирует внешний шум.</li></ul><br/><ul><li><b>Дизайнерские Опции:</b> Идеально подходит для современных интерьеров с различными вариантами цвета и модели.</li></ul><br/><h3>Области Применения:</h3><br/><ul><li><b>Жилое Использование:</b> Подходит для оконных систем в домах, виллах и загородных домах.</li></ul><br/><ul><li><b>Коммерческая Недвижимость:</b> Широко используется в офисных зданиях, торговых центрах и ресторанах.</li></ul><br/><ul><li><b>Спортивные Комплексы:</b> Идеальный выбор для окон в спортивных центрах.</li></ul>",
    
        table_de: "<ul><li><b>Materialqualität:</b> Hergestellt aus hochwertigem PVC-Material. Beständig gegen Witterungseinflüsse und Abrieb.</li></ul><br/><ul><li><b>Wärme- und Schallisolation:</b> Bietet hohe Wärmeisolierung und reduziert Energiekosten. Minimiert äußeren Lärm.</li></ul><br/><ul><li><b>Designmöglichkeiten:</b> Ideal für moderne Innenräume mit verschiedenen Farb- und Modelloptionen.</li></ul><br/><h3>Anwendungsbereiche:</h3><br/><ul><li><b>Privater Gebrauch:</b> Geeignet für Fenstersysteme in Häusern, Villen und Ferienhäusern.</li></ul><br/><ul><li><b>Gewerbeimmobilien:</b> Weit verbreitet in Bürogebäuden, Einkaufszentren und Restaurants.</li></ul><br/><ul><li><b>Sportanlagen:</b> Ideal für Fenster in Sportzentren.</li></ul>",
    }
    
    
    
    
];

export default product;
