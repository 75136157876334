import React, { useContext, useState } from "react";
import video1 from "../assets/image/video1.mp4"; // Video 1
import video2 from "../assets/image/video2.mp4"; // Video 2
import BreadCrumb from "../pages/BreadCrumb";
import video1img from "../assets/image/video1img.png";
import video2img from "../assets/image/videoimg2.png";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";

const WhyUs = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [isModalOpen, setIsModalOpen] = useState(false); // Modalın açılıb-açılmadığını idarə etmək üçün state.
  const [currentVideo, setCurrentVideo] = useState(""); // Hansi video oynadılacaqsa onu müəyyən etmək üçün state.

  const openModal = (videoSource) => {
    setCurrentVideo(videoSource); // Kliklənən videonu modalda açmaq üçün video mənbəyini təyin edirik.
    setIsModalOpen(true); // Modalı açır.
  };

  const closeModal = () => {
    setIsModalOpen(false); // Modalı bağlayır.
    setCurrentVideo(""); // Video mənbəyini təmizləyirik.
  };

  return (
    <>
      {/* İlk video */}
      <BreadCrumb title={t.whyus} />
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-6 col-sm-12 col-12">
            <div
              onClick={() => openModal(video1)} // Video 1 kliklənəndə açılacaq
              style={{
                width: "100%",
                height: "400px",
                backgroundColor: "#fff",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: "#000",
                  fontSize: "20px",
                  position: "relative",
                }}
                className="d-flex justify-content-center"
              >
                <img src={video1img} alt="" className="img-fluid" style={{width:"80%"}} />
                <span
                  style={{
                    fontSize: "30px",
                    transform: "translateY(5px)",
                    position: "absolute",
                    top: "50%",
                    left: "45%",
                    background:"rgb(7, 22, 137)",
                    color:"#fff",
                    borderRadius:"50%",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    width:"50px",
                    height:"50px"
                  }}
                  
                >
                 <span className="ms-2">
                 &#9654;
                 </span>
                </span>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-12">
            <div
              onClick={() => openModal(video2)} // Video 2 kliklənəndə açılacaq
              style={{
                width: "100%",
                height: "400px",
                backgroundColor: "#fff",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: "#000",
                  fontSize: "20px",
                  position: "relative",
                }}
                className="d-flex justify-content-center"
              >
                <img src={video2img} alt="" className="img-fluid" style={{width:"73%"}} />
                <span
                  style={{
                    fontSize: "30px",
                    transform: "translateY(5px)",
                    position: "absolute",
                    top: "50%",
                    left: "45%",
                    background:"rgb(7, 22, 137)",
                    color:"#fff",
                    borderRadius:"50%",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    width:"50px",
                    height:"50px"
                  }}
                  
                >
                 <span className="ms-2">
                 &#9654;
                 </span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* İkinci video */}

      {/* Modalda video göstərilməsi */}
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: "relative",
              maxWidth: "90%",
              maxHeight: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <video
              autoPlay
              muted={false} // Səsli oynatmaq üçün muted=false
              loop
              style={{
                objectFit: "contain", // Video orijinal ölçüsünü qorumağa kömək edir
                width: "100%",
                height: "800px",
              }}
            >
              <source src={currentVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                border: "none",
                padding: "10px 15px 8px 15px",
                borderRadius: "50%",
                fontSize: "18px",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default WhyUs;
