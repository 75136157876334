import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Home from "../components/Home/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Product from "../pages/Product";
import ProductDetails from "../pages/ProductDetails";
import ScrollToTop from "../components/ScrollToTop";
import Faq from "../pages/Faq";
import Calc from "../pages/Calc";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import SeoAbout from "../pages/SeoAbout";
import WhyUs from "../pages/WhyUs";

const AppRouter = () => {
  const [active, setActive] = useState(false);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActive(true);
      const hideTimeout = setTimeout(() => {
        setHidden(true);
      }, 800);
      return () => clearTimeout(hideTimeout);
    }, 800);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      <div
        id={`preloader`}
        className={`${active ? "preloaded" : ""} ${hidden ? "d-none" : ""}`}
      >
        <div className="loader--border"></div>
      </div>
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/plastik-qapi-pencere" element={<SeoAbout />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/blog/:blogTitle" element={<BlogDetails />}></Route>
          <Route path="/faq" element={<Faq />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/product" element={<Product />}></Route>
          <Route path="/calc" element={<Calc />}></Route>
          <Route path="/WhyUs" element={<WhyUs />}></Route>
          <Route path="/:productName" element={<ProductDetails />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
